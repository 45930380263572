import { useEffect, useMemo, useState } from "react";
import { atomWithStorage } from "jotai/utils";
import { useAtom } from "jotai";

export type Token = {
  uid: string;
  client: string;
  "access-token": string;
  accountType: string;
};

export interface SessionState {
  token?: Token;
  currentUser?: any;
}

const sessionAtom = atomWithStorage<SessionState>("uoma-coach-session", {});

export function useSession() {
  const [session, setSession] = useAtom(sessionAtom);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return useMemo(() => {
    const signOut = () => {
      setSession({});
    };

    return {
      loading,
      signedIn: !!session.token,
      token: session.token,
      currentUser: session.currentUser,
      session,
      setSession,
      signOut,
    };
  }, [session, setSession]);
}
