import React from "react";

import { useEffect } from "react";
import { getCenteredLayout } from "../layouts/layoutGetters";
import { useSession } from "../utils/hooks/useSession";
import { useRouter } from "next/router";

function HomePage() {
  const { currentUser } = useSession();
  const router = useRouter();

  useEffect(() => {
    if (currentUser) {
      router.push("/messages");
    } else {
      router.push("/coach/login");
    }
  }, [currentUser, router]);

  return <></>;
}

HomePage.getLayout = getCenteredLayout;

export default HomePage;
