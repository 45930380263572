import React from "react";

import { useEffect } from "react";
import { useSession } from "../utils/hooks/useSession";
import { useRouter } from "next/router";

const Authenticated = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();

  const { currentUser, loading } = useSession();

  useEffect(() => {
    if (!currentUser && router && !loading) {
      router.push("/coach/login");
    }
  }, [currentUser, router, loading]);

  return <>{children}</>;
};

export default Authenticated;
