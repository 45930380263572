import Link from "next/link";
import ErrorBoundary from "../components/ErrorBoundary";
import { useSession } from "../utils/hooks/useSession";
import { ReactNode, Suspense, useEffect, useState } from "react";
import RoleBoundary from "../components/RoleBoundary";
import { useRouter } from "next/router";
import styles from "./AdminLayout.module.css";
import Button from "../components/Button";
import logo from "../icons/logo.svg";
import { useTranslation } from "react-i18next";

type AllowedRouter = {
  [key: string]: string[];
};

const checkIfRouteAllowed = (route: string, accountType: string) => {
  if (accountType === "administrators") return true;
  else {
    const allowedRoutes: AllowedRouter = {
      coaches: ["/accounts?role=customers", "/cohorts"],
    };
    //check if accountType is in allowedRoutes
    if (accountType in allowedRoutes) {
      //check if route is in allowedRoutes[accountType]
      if (allowedRoutes[accountType].includes(route)) {
        return true;
      } else return false;
    }
    return false;
  }
};

type NavItem = {
  label: string;
  url: string;
  acceptedRoles: Array<string>;
};

const AdminLayout = ({ children }: { children: ReactNode }) => {
  //const [isNavOpen, setIsNavOpen] = useState(true);

  // const toggleNav = () => setIsNavOpen(!isNavOpen);
  const { session, signOut } = useSession();
  const router = useRouter();

  /*
  useEffect(() => {
    // setIsNavOpen(false);
  }, [router.asPath]);

  */
  useEffect(() => {
    if (session.token) {
      const routeAllowed = checkIfRouteAllowed(
        router.asPath,
        session?.token?.accountType
      );
      //////console.log("routeAllowed", routeAllowed);

      if (!routeAllowed) {
        // router.back();
      }
    }
  }, []);

  //console.log("logo", logo);

  return (
    <>
      {/* <button
        onClick={toggleNav}
        style={{ position: "absolute", top: 0, right: 0 }}>
        ☰
      </button>*/}
      <div className={styles["layout-container"]}>
        <Navigation signOut={signOut} />
        <div>{children}</div>
      </div>
    </>
  );

  //...
};

const Navigation = ({ signOut }: { signOut: () => void }) => {
  const { t } = useTranslation();

  const navItems: NavItem[] = [
    {
      label: "Dashboard",
      url: "/admin",
      acceptedRoles: ["administrators"],
    },
    {
      label: "Admins",
      url: "/accounts?role=administrators",
      acceptedRoles: ["administrators"],
    },
    {
      label: "Coaches",
      url: "/accounts?role=coaches",
      acceptedRoles: ["administrators"],
    },
    /*{
    label: "Customers",
    url: "/accounts?role=customers",
    acceptedRoles: ["administrators", "coaches"],
  },
  */
    {
      label: "Cohorts",
      url: "/cohorts",
      acceptedRoles: ["administrators"],
    },
    {
      label: "Metrics",
      url: "/metrics",
      acceptedRoles: ["administrators"],
    },
    {
      label: t("messages.messages"),
      url: "/messages",
      acceptedRoles: ["administrators", "coaches"],
    },
    {
      label: "Notes",
      url: "/notes",
      acceptedRoles: ["administrators"],
    },
  ];

  return (
    <div className={styles["nav-container"]}>
      <img src={logo.src} className="margin-1rem " />

      <nav className={styles["nav"]}>
        <ul className={styles["nav-list"]}>
          {navItems.map((item) => (
            <RoleBoundary key={item.url} acceptedRoles={item.acceptedRoles}>
              <li className={styles["nav-item"]}>
                <Link href={item.url}>{item.label}</Link>
              </li>
            </RoleBoundary>
          ))}
        </ul>
      </nav>

      <Button className="margin-1rem margin-left" onClick={signOut}>
        {t("auth.log_out")}
      </Button>
    </div>
  );
};

export default AdminLayout;
