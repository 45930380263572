//component that accepts an arry of roles and a child component and renders the
//child component only if the user has one of the roles in the array.
//If the user does not have one of the roles, the component renders null.
//The component should also accept a fallback component that is rendered
//if the user does not have one of the roles.The fallback component should be optional
//and if not provided, the component should render null.
import React from "react";
import { ReactNode } from "react";
import { useSession } from "../utils/hooks/useSession";

const RoleBoundary = ({
  acceptedRoles,
  children,
}: {
  acceptedRoles: Array<string>;
  children: ReactNode;
}) => {
  const { session } = useSession();

  if (
    session &&
    session.token &&
    acceptedRoles.includes(session.token.accountType) &&
    children
  ) {
    return <>{children}</>;
  } else {
    return <>{null}</>;
  }
};

export default RoleBoundary;
