import { useEffect } from "react";
import Authenticated from "../components/Authenticated";
import { useLogin } from "../utils/hooks/useLogin";
import { useSession } from "../utils/hooks/useSession";
import AdminLayout from "./AdminLayout";
import CenteredLayout from "./CenteredLayout";
import { useRouter } from "next/router";

export const getCenteredLayout = (page: any) => {
  return (
    <>
      <CenteredLayout>{page}</CenteredLayout>
    </>
  );
};

export const getAuthenticatedAdminLayout = (page: any) => {
  return (
    <Authenticated>
      <AdminLayout>{page}</AdminLayout>
    </Authenticated>
  );
};
